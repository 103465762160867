<template>
    <modal :name="modal_name" class="final-modal stock-modal" transition="nice-modal-fade">
        <div class="v-modal-content">
            <div class="v-modal-header">
                <span class="v-modal-dialog-title v-modal-title">CALCULATION</span>
                <button type="button" class="closeModal" aria-label="Close" @click="hideQuantitySold()">
                    <span aria-hidden="true">
                        <i class="icon icon-close"></i>
                    </span>
                </button>
            </div>
            <div class="v-modal-body pt-0 pb-0">
                <div class="v-modal-layout d-form">
                    <div class="mt-8 mb-8">
                        <h6 class="text-secondary font-weight-bold text-center">
                            Total of Quantity Sold Reflected in Variant Count Table
                        </h6>
                    </div>
                </div>
            </div>
           
        </div>
    </modal>
</template>
<script>
export default {
    props:['modal_name'],
    methods:{
        hideQuantitySold() {
            this.$modal.hide(this.modal_name);
        }    
    }
}
</script>
<style scoped>
.border-radius{
    border-radius: 12px;
}
.quantity-details{
    flex: 1 1 250px;
    font-size: 16px;
    text-align: center;
}
.bx-shadow{
    box-shadow: 3px 0 0px -2px #989898;
}
.final-cost{
    border-top: solid 1px #989898;
    border-bottom: solid 1px #989898;
}
</style>